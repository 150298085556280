import axios from '@axios'
import { assign } from 'lodash'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('stations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            return new Promise((resolve, reject) => {
                axios.get(`stations/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        assign: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post(`allocate`, params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        allocate: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post(`allocate_users`, params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        warehouses: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('main_warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        users: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('stationed_users', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
