<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
            </b-col>

            <b-col cols="12" md="8">
                <card-statistic />
            </b-col>
        </b-row>

        <b-row>
            <!-- Left Table Column -->
            <b-col cols="12" md="7">
                <b-row class="mb-1">
                    <!-- Allocate Button -->
                    <b-col cols="12" class="d-flex justify-content-end">
                        <b-button size="sm" variant="outline-success" @click="handleAllocateUser(true)"
                            :disabled="saving">
                            <feather-icon icon="ArrowRightIcon" class="ml-1" />
                        </b-button>
                    </b-col>
                </b-row>

                <!-- Left Table (Unassigned Users) -->
                <b-row>
                    <!-- Count of selected rows -->
                    <!-- <b-col cols="12" class="d-flex justify-content-start mb-2">
                        <span>Selected: {{ stationAllocate.users.length }} user(s)</span>
                    </b-col> -->
                    <b-col cols="12">
                        <!-- Search Input -->
                        <b-form-input v-model="searchQueryLeft" placeholder="Search by Name..." size="sm"
                            class="mb-2" />

                        <!-- Table of Users (Unassigned) -->
                        <b-table :items="filteredLeftUsers" :fields="usersColumns" small responsive="sm"
                            sticky-header="480px">
                            <!-- Select All Checkbox in the Header -->
                            <template #head(select)>
                                <b-form-checkbox v-model="selectAll.left" @change="toggleAll(true)"></b-form-checkbox>
                            </template>

                            <template #cell(select)="data">
                                <b-form-checkbox v-model="stationAllocate.users"
                                    :value="data.item.id"></b-form-checkbox>
                            </template>

                            <!-- Other Cell Templates -->
                            <template #cell(person)="data">
                                <span>{{ formatName(data.item.person) }}</span>
                            </template>

                            <template #cell(role)="data">
                                <span>{{ data.item.roles[0].title }}</span>
                            </template>

                            <template #cell(station)="data">
                                <span>{{ data.item.station.level.toUpperCase() }} ({{
                                    data.item.station.name.toUpperCase() }})</span>
                            </template>

                            <template #cell(incharge)="data">
                                <span v-if="data.item.incharge">
                                    <b-badge variant="light-success">Yes</b-badge>
                                </span>
                                <span v-else>
                                    <b-badge variant="light-secondary">No</b-badge>
                                </span>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-col>

            <!-- Right Table Column -->
            <b-col cols="12" md="5">
                <b-row class="mb-1">
                    <!-- Deallocate Button -->
                    <b-col cols="12" class="d-flex justify-content-start">
                        <b-button size="sm" variant="outline-success" @click="handleAllocateUser(false)"
                            :disabled="saving">
                            <feather-icon icon="ArrowLeftIcon" class="ml-1" />
                        </b-button>
                    </b-col>
                </b-row>

                <!-- Right Table (Assigned Users) -->
                <b-row>
                    <!-- Count of selected rows -->
                    <!-- <b-col cols="12" class="d-flex justify-content-start mb-2">
                        <span>Selected: {{ stationAllocate.users.length }} user(s)</span>
                    </b-col> -->
                    <b-col cols="12">
                        <!-- Search Input -->
                        <b-form-input v-model="searchQueryRight" placeholder="Search by Name..." size="sm"
                            class="mb-2" />

                        <!-- Table of Users (Assigned) -->
                        <b-table :items="filteredRightUsers" :fields="stationedUsersColumns" small responsive="sm"
                            sticky-header="480px">
                            <!-- Select All Checkbox in the Header -->
                            <template #head(select)>
                                <b-form-checkbox v-model="selectAll.right" @change="toggleAll(false)"></b-form-checkbox>
                            </template>

                            <template #cell(select)="data">
                                <b-form-checkbox v-model="stationAllocate.users"
                                    :value="data.item.id"></b-form-checkbox>
                            </template>

                            <!-- Other Cell Templates -->
                            <template #cell(person)="data">
                                <span>{{ formatName(data.item.person) }}</span>
                            </template>

                            <template #cell(role)="data">
                                <span>{{ data.item.roles[0].title }}</span>
                            </template>

                            <template #cell(incharge)="data">
                                <span v-if="data.item.incharge">
                                    <b-badge variant="light-success">Yes</b-badge>
                                </span>
                                <span v-else>
                                    <b-badge variant="light-secondary">No</b-badge>
                                </span>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BMedia, BAvatar, BLink, BImg,
    BBadge,
} from 'bootstrap-vue'
import CardStatistic from '@/views/card/card-statistic/CromisCardStatistic'
import LocationFilters from '@core/components/cromis/filters/LocationFilters'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, onMounted, computed, watch } from '@vue/composition-api'
import workStationStoreModule from '@/views/cromis/station/workStationStoreModule'
import moment from 'moment'
export default {
    props: {
        id: {
            default: 0,
        },
        category: {
            default: 0,
        },
    },
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BMedia, BAvatar, BLink, BImg, BBadge,
        ValidationObserver, ValidationProvider,

        LocationFilters, CardStatistic,
    },
    directives: {},
    setup(props, context) {
        const saving = ref(false)
        const selectAll = ref({ left: false, right: false });
        const searchQueryLeft = ref('');
        const searchQueryRight = ref('');
        const station = ref(null)
        const users = ref([])
        const stationId = ref(null)
        const usersColumns = ref([
            { key: "select", label: "" },
            { key: 'person', label: 'Full Name', sortable: false },
            { key: 'mobile', sortable: false },
            { key: 'role', label: 'Role', sortable: false },
            { key: 'station', label: 'Station', sortable: false },
            { key: 'incharge', label: 'In Charge?', sortable: false },
        ])

        const stationedUsersColumns = ref([
            { key: "select", label: "" },
            { key: 'person', label: 'Name', sortable: false },
            { key: 'mobile', label: 'Contact #', sortable: false },
            { key: 'role', label: 'Role', sortable: false },
            { key: 'incharge', label: 'In Charge?', sortable: false },
        ])

        const stationAllocate = ref({
            station_id: null,
            allocate: true,
            users: []
        })
       // Left Table Filter: Exclude users assigned to the current station
       const filteredLeftUsers = computed(() => {
            return users.value.filter(user => 
                user.station.id !== station.value.id &&
                (user.person.firstName.toLowerCase().includes(searchQueryLeft.value.toLowerCase()) || 
                user.person.lastName.toLowerCase().includes(searchQueryLeft.value.toLowerCase()))
            );
        });

        // Right Table Filter: Only show users assigned to the current station
        const filteredRightUsers = computed(() => {
            return users.value.filter(user => 
                user.station.id === station.value.id &&
                (user.person.firstName.toLowerCase().includes(searchQueryRight.value.toLowerCase()) || 
                user.person.lastName.toLowerCase().includes(searchQueryRight.value.toLowerCase()))
            );
        });

        
         // Computed properties for selected users
         const selectedLeftUsers = computed(() => {
            return stationAllocate.value.users.filter(user => filteredLeftUsers.value.includes(user));
        });

        const selectedRightUsers = computed(() => {
            return stationAllocate.value.users.filter(user => filteredRightUsers.value.includes(user));
        });

        // Method to format user name
        const formatName = (person) => {
            return `${person.firstName} ${person.middleName ? person.middleName.charAt(0).toUpperCase() + '.' : ''} ${person.lastName}`;
        };


        const CROMIS_STORE_MODULE_NAME = 'cromis-station'


        // Computed properties for filtering users
        const leftTableUsers = computed(() => users.value.filter(e => e.station.id !== station.value.id));
        const rightTableUsers = computed(() => users.value.filter(e => e.station.id === station.value.id));

        // Watch for changes in selected users to update `selectAll`
        watch(stationAllocate.value.users, (newSelectedUsers) => {
            selectAll.value.left = newSelectedUsers.length === leftTableUsers.value.length;
            selectAll.value.right = newSelectedUsers.length === rightTableUsers.value.length;
        });



        // Toggle selection for a specific table
        const toggleAll = (isAllocating) => {
            if (isAllocating) {
                // Select or Deselect all users from the unassigned users table
                if (selectAll.value.left) {
                    stationAllocate.value.users = users.value
                        .filter(e => e.station.id !== station.value.id)
                        .map(e => e.id);
                } else {
                    stationAllocate.value.users = [];
                }
            } else {
                // Select or Deselect all users from the stationed users table
                if (selectAll.value.right) {
                    stationAllocate.value.users = users.value
                        .filter(e => e.station.id === station.value.id)
                        .map(e => e.id);
                } else {
                    stationAllocate.value.users = [];
                }
            }
        };
        const handleAllocateUser = async (isAllocating) => {
            console.log('clicked');
            console.log(selectedLeftUsers);

            // Ensure at least one user is selected
            if (!stationAllocate.value.users.length) {
                context.root.$swal({
                    icon: 'error',
                    title: 'No Users Selected',
                    text: 'Please select users before proceeding.',
                    showConfirmButton: true,
                    customClass: { confirmButton: 'btn btn-danger' },
                    buttonsStyling: false,
                });
                return;
            }

            // Set allocate dynamically based on the action
            stationAllocate.value.allocate = isAllocating; // true = allocate, false = deallocate
            stationAllocate.value.station_id = station.value.id; // Ensure station ID is set

            try {
                // Send request to allocate or deallocate users
                await store.dispatch('cromis-station/allocate', stationAllocate.value);

                // Refresh user list from the server
                const response = await store.dispatch('cromis-station/users', { id: props.id });
                users.value = response.data.users;

                // Show success message
                context.root.$swal({
                    icon: 'success',
                    text: `User ${isAllocating ? "Allocation" : "Deallocation"} successful!`,
                    showConfirmButton: true,
                    timer: 5000,
                    customClass: { confirmButton: 'btn btn-primary' },
                    buttonsStyling: false,
                });

                // Clear selected users
                stationAllocate.value.users = [];
            } catch (error) {
                console.error("Allocation error:", error);

                if (error.response?.status === 422) {
                    serverErrors.value = error.response.data.errors;
                } else {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. Contact tech support.',
                        showConfirmButton: true,
                        customClass: { confirmButton: 'btn btn-danger' },
                        buttonsStyling: false,
                    });
                }
            }
        };





        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, workStationStoreModule)

        // Unregister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            stationAllocate.value = {
                station_id: parseInt(props.id),
                allocate: true,
                users: []
            }



            await store.dispatch('cromis-station/show', { id: props.id })
                .then(response => {
                    station.value = response.data
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-station/users', { id: props.id })
                .then(response => {
                    users.value = response.data.users
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })

        return {
            // Data
            station,
            users,
            saving,
            stationAllocate,
            selectAll,
            // Table
            usersColumns,
            stationedUsersColumns,
            searchQueryLeft,
            searchQueryRight,
            filteredLeftUsers,
            filteredRightUsers,
            selectedLeftUsers,
            selectedRightUsers,
            formatName,

            // Methods
            toggleAll,
            handleAllocateUser,

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
